@import '../../base.scss';

@keyframes pulse {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: 20;
    position: absolute;
    top: 0px; 
}

.header_scroll {
    background-color: $background-header;
    position: fixed;
    top: 0px;
    z-index: 20;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px 20px 10px;
}

.info{
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

///логотип

.box_logo{
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}


.logo_link{
    box-sizing: content-box;
    display: flex;
    align-items: center;
    width: 200px;
    margin-top: -10px;
}

.logo{
    width: 150px;
    height: auto;
}

.logo_min {
    display: none;
}

.yandex{
    width: 200px;
    height: auto;
    margin-right: 30px;
}

//// телефон и соцсети

.contacts{
    max-width: 320px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box_phone{
    max-width: 225px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.phone{
    font-size: 25px;
    line-height: 1;
    font-weight: 500;
    margin: 0;
    text-decoration: none;
    color: $white;
    vertical-align: bottom;
    color: $white;

    &:hover{
        cursor: pointer;
        opacity: .5;
        transition: .3s;
    }
}

.call {
    max-width: 100%;
    width: 85%;
    box-sizing: border-box;
    position: absolute;
    background-color: #00c451;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    left: 0;
    top: -23px;
    padding: 5px 15px;
}

.call_pulse {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: $white;
    animation: pulse 1s infinite linear;
}

.call_text {
    color: $white;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    margin: 0;
}

/// меню
.nav{
    max-width: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.link{
    color: $white;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    text-decoration: none;
    position: relative;
    transition: all .4s ease-out;
    padding: 10px 7px;
    border-radius: 6px;

    &:hover{
        cursor: pointer;
        transition: all .4s ease-out;
        background: rgba(#939398, 0.6);
    }
}

.line{
    width: 1px;
    height: 14px;
    background: $white;
}

.button  {
    display: none;
}

@media(max-width: 900px){
    .yandex{
        display: none;
    }
    
    .contacts{
        max-width: 320px;
    }
}

@media(max-width: 768px){

    .box{
        padding: 20px 10px 5px;
        align-items: center;
    }

    .box_logo{
        padding-bottom: 0;
    }

    .logo_link{
        width: 150px;
    }
    
    .logo{
        width: 150px;
    }

    
    .item_phone{
        display: block;
        box-sizing: content-box;
    }

    .contacts{
        max-width: 180px;
        margin-left: 0px;
    }

    .box_social {
        display: none;
    }

    .phone{
        font-size: 20px;
    }

    .call {
        width: 100%;
        box-sizing: border-box;
        position: absolute;
        background-color: #00c451;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        left: 0;
        top: -23px;
        padding: 5px 15px;
    }

    .nav{
        display: none;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        padding: 0;
        border: 0;
        background-image: url(../../image/Header/burger.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 32px;
        height: 20px;

        &:hover{
            cursor: pointer;
            filter: drop-shadow(0 0 5px $white);
            transition: all .3s ease-out;
        }
    }
}

@media(max-width: 500px){
    .logo_link{
        width: 60px;
    }
    
    .logo{
        display: none;
    }

    .logo_min{
        display: block;
        width: 100px;
        height: auto;
    }
}

@media(max-width: 425px){

    .logo_link{
        width: 70px;
    }

    .logo_min{
        width: 70px;
    }


    .contacts{
        max-width: 160px;
    }

    .phone{
        font-size: 18px;
    }

    .call_text {
        font-size: 11px;
    }
}

@media(max-width: 380px){
    .contacts{
        max-width: 150px;
    }

    .phone{
        font-size: 16px;
    }
}