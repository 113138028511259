@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    @include title;
    margin-bottom: 20px;
}

.list {
    max-width: 100%;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    overflow: hidden;
    padding: 20px;
}

.image {
    width: auto;
    height: 150px;
    border-radius: 50%;
}

.item_title {
    color: $title;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
    margin: 10px 0;
    text-align: center;
}

.item_text {
    color: $title;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    margin: 0;
    text-align: center;

    & span {
        font-weight: 600;
    }

}


@media(max-width: 768px){
    .list {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 10px;
    }
}