@import '../../base.scss';

@keyframes pulse-1 {
    0%{
        transform: scale( .9);
        opacity: .9;
    }
    100%{
        transform: scale(1.4);
        opacity: .3;
    }
}

    .main{
        @include main-column;
    }

    .box{
        box-sizing: border-box;
        max-width: 1440px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 80px 105px 100px;
        background-color: $box;
        border-radius: 26px;
        z-index: 3;
    }

    .box_text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 3;
    }

    .title{
        @include title;
        max-width: 700px;
        text-align: left;
    }

    .button {
        @include button;
        margin-top: 40px;
    }

    .box_image{
        position: absolute;
        bottom: -3px;
        right: 150px;
        width: 480px;
        z-index: 2;
    }

    .image {
        width: 100%;
        height: auto;
    }

// подпись к фото
    .image_info {
        box-sizing: border-box;
        position: absolute;
        bottom: 10px;
        left: 10px;
        background-color: rgba(#3e4d57, 0.3);
        backdrop-filter: blur(5px);
        border-radius: 8px;
        transform: skew(-10deg);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px 10px;
    }

    .image_title {
        color: $white;
        font-size: 18px;
        line-height: 1.2;
        margin: 0 0 5px
    }

    .image_text {
        font-size: 16px;
        line-height: 1.2;
        color: $white;
        margin: 0;
    }


// блок с маркерами
    .box_pulse {
        margin-top: 30px;
        max-width: 500px;
        display: grid;
        box-sizing: border-box;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 10px;
        z-index: 3;
    }

    .box_item {
        display: flex;
        align-items: center;
    }

    .icon_boxs {
        min-width: 32px;
        height: 32px;
        border-radius: 50%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .icon_bg{
        min-width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #d6625e;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
    }

    .pulse{
        background: #d6625e;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: auto;
        width: auto;
        border-radius: 50%;
        animation: pulse-1 1s infinite;
        z-index: 1;
    }

    .icon{
        width: 20px;
        height: auto;
        z-index: 2; 
    }

    .text{
        max-width: 400px;
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2;
        color: $title;
        opacity: .8;
        margin: 0 10px;
    }

    @media(max-width: 1180px){
        .box_image{
            right: 30px;
        }
        }

    @media(max-width: 1080px){
        .box_image{
            width: 400px;
        }

        .text{
            max-width: 400px;
            font-size: 16px;
        }

        .percent {
            width: 200px;
            bottom: -50px;
            left: -150px;
        }

        .image_info {
            padding: 3px 7px;
        }
    
        .image_title {
            font-size: 16px;
        }
    
        .image_text {
            font-size: 14px;
        }
    }

    @media(max-width: 980px){
        .box_image{
            width: 350px;
            right: 0px;
        }

        .box_pulse {
            max-width: 400px;
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        }

        .icon_boxs {
            min-width: 27px;
            height: 27px;
        }
    
        .icon_bg{
            min-width: 25px;
            height: 25px;
        }

        .icon{
            width: 15px;
        }
    
    }

    @media(max-width: 900px){
        .box_image{
            width: 320px;
        }
    }

    @media(max-width: 768px){
        .box{
            padding: 40px 30px 250px;
        }
    }

    @media(max-width: 550px){
        .box{
            padding: 40px 30px 330px;
        }
    }

    @media(max-width: 500px){
        .box_pulse {
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    @media(max-width: 425px){
        .image_info {
            left: 50px;
            padding: 2px 5px;
        }
    
        .image_title {
            font-size: 14px;
        }
    
        .image_text {
            font-size: 12px;
        }
    }