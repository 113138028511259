$white: #FFF;
$black: #242428;
$blue: #0700ff;
$red:#cd2122;
$element: #CEAE8B;

/*background*/
$background: $white;
$background-footer: #242428;
$background-header: rgba(#242326, 0.6);
$box: #eff3f4;


/*title & text*/
$title: #242428;
$text: #242629;
$text-box: #3e4d57;

/*button*/
$button-background: linear-gradient(180deg, #CE2620 0%, #D6625E 100%);
$button-color: $white;
$button-border: #AC221D;
$button-shadow: #D6625E;




@mixin main-column {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    padding: 20px 20px;

    @media(max-width: 768px){
        padding: 20px 10px;
    }

    @media(max-width: 425px){
        padding: 15px 10px;
    }
}

@mixin title {
    font-weight: 400;
    font-size: 40px;
    line-height: 1.1;
    max-width: 1180px;
    width: 100%;
    text-align: center;
    margin: 0;
    color: $title;

    @media(max-width: 1080px){
        font-size: 40px;
    }

    @media(max-width: 768px){
        font-size: 30px;
    }

    @media(max-width: 500px){
        font-size: 28px;
    }
    
    @media(max-width: 425px){
        font-size: 26px;
    }

    & span {
        color: #D6625E;
        font-weight: 600;
    }

}

@mixin text {
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    color: $title;
    opacity: .9;
    margin: 20px 0 0;

    @media(max-width: 1080px){
        font-size: 18px;
    }

    @media(max-width: 768px){
        font-size: 16px;
        margin: 10px 0 0;
    }
}

@mixin text-box {
    max-width: 400px;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    color: $title;
    opacity: .8;
    margin: 30px 0 0;

    @media(max-width: 1080px){
        max-width: 350px;
        font-size: 18px;
}

    @media(max-width: 768px){
        max-width: 300px;
        font-size: 16px;
        margin: 20px 0 0;
}
}

@mixin button{
    max-width: 350px;
    width: 100%;
    height: 50px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
    border: none;
    text-transform: uppercase;
    margin: 20px 0 0;
    padding: 0;
    color: $button-color;
    border-radius: 100px;
    background: $button-background;
    box-shadow: 0 4px 0 $button-border;
    transform: translateY(-5px);


    &:hover{
        cursor: pointer;
        box-shadow: 0px 0px 0px 0px rgba($button-shadow, 0.25), 0px 0px 0px 0px rgba($button-shadow, 0.25);
        transform: scale( .95 );
        transition: all .5s ease-out;
    }

    @media(max-width: 768px){
        height: 50px;
        margin: 20px 0 0;
        font-size: 14px;
    }
}



