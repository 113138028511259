@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    @include main-column;
    padding: 30px 20px;
}

.title{
    @include title;
    margin-bottom: 10px
}

.list{
    max-width: 1400px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
    padding: 20px 10px;
    overflow: hidden;
}

.cell{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba( $background-footer, .4 );
    background: linear-gradient(180deg, #FEFEFE 0%, #ECECEC 100%);
    overflow: hidden;

    &:hover{
        cursor: pointer;
        transform:scale(1.05);
        transition: .5s;
    }
}

.cell_image{
    width: 100%;
    border-radius: 8px;
}

.cell_info{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.cell_subtitle{
    font-size: 16px;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    max-width: 300px;
}

.cell_button{
    @include button;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    max-width: 350px;
    width: 100%;
    height: 40px;
    margin: 20px 0 0;
}

@media(max-width: 1130px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media(max-width: 768px){
    .title{
        @include title;
        margin-bottom: 0;
    }

    .box{
        padding: 10px;
    }

    .cell_button{
        height: 40px;
    }
}

@media(max-width: 425px){
    .box{
        padding: 10px;
    }

    .list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .cell_button{
        height: 40px;
    }
}