@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    overflow: hidden;
    gap: 20px;
}

.box_map {
    box-sizing: border-box;
    max-width: 800px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
}

.info {
    max-width: 42%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    @include title;
    text-align: left;
    margin-bottom: 20px;
}

.box_contacts {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.icon_box {
    width: 40px;
    height: 40px;
    border: 1px solid rgba($blue, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 10px;
}

.icon_contacts {
    width: 20px;
    height: auto;
}

.subtitle {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    color: #777;
    margin: 20px 0;
}

.link_phone {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    color: $title;
    text-decoration: none;
    transition: .3s;

    &:hover {
        cursor: pointer;
        transition: .3s;
        color: $element;
    }
}

.link_email {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 400;
    color: #529DCD;
    transition: .3s;

    &:hover {
        cursor: pointer;
        transition: .3s;
        color: #D6625E;
    }
}

.text {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 400;
    color: $title;
    margin: 0;
}

.box_contacts_social {
    max-width: 320px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

@media(max-width: 768px){
    .box{
        flex-direction: column;
    }

    .info {
        max-width: 100%;
    }

    .icon_box {
        width: 30px;
        height: 30px;
    }
    
    .icon_contacts {
        width: 15px;
    }
    
    .subtitle {
        font-size: 16px;
    }
    
    .link_phone {
        font-size: 16px;
    }
    
    .link_email {
        font-size: 16px;
    }
    
    .text {
        font-size: 16px;
    }
}