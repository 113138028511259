@import '../../base.scss';

.modal{
    font-family:  "Montserrat", "Arial ", sans-serif;
    box-sizing: border-box;
    position: relative;
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #FEFEFE 0%, #ECECEC 100%);
    border-radius: 20px;
    padding: 30px;
    z-index: 20;
    overflow: hidden;
}

.title{
    @include title;
    text-align: center;
    margin: 0;
    color: $element;
}

.text{
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    color: $title;
    margin: 15px 0 0;
}

.button{
    @include button;
    margin-top: 20px;
}

@media(max-width: 768px){
    .modal{
        max-width: 400px;
    }
}

@media(max-width: 425px){
    .text{
        font-size: 14px;
    }

    .button{
        width: 90%;
    }
}

